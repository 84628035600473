@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'Amazon';
  src: url('./fonts/AmazonEmber_Rg.ttf') format ('truetype');
} */

@font-face {
  font-family: 'AmazonMedium';
  src: url( './fonts/AmazonEmber_Th.ttf' ) format( 'truetype' );
}

@font-face {
  font-family: 'AmazonBold';
  src: url( './fonts/AmazonEmber_Bd.ttf' ) format( 'truetype' );
}


@font-face {
  font-family: 'Amazon';
  src: url( './fonts/AmazonEmber_Rg.ttf' ) format( 'truetype' );
}


/* tr:nth-child(even) {
  background: #f2f2f2;
  }
  tr:nth-child(od) {
  /*    class="bg-white";
  background: #ffffff;
  }

  .roboto-thin {
    font-family: "Amazon", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .roboto-light {
    font-family: "Amazon", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto-regular {
    font-family: "Amazon", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto-medium {
    font-family: "Amazon", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-bold {
    font-family: "Amazon", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-black {
    font-family: "Amazon", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .roboto-thin-italic {
    font-family: "Amazon", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .roboto-regular-italic {
    font-family: "Amazon", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .roboto-medium-italic {
    font-family: "Amazon", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .roboto-bold-italic {
    font-family: "Amazon", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .roboto-black-italic {
    font-family: "Amazon", sans-serif;
    font-weight: 900;
    font-style: italic;
  } */
  
body {
  font-family: 'Amazon' !important;
  box-sizing: border-box;
}
select > option {
  font-family: 'Amazon' !important;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px; /* Adjust the border-radius as needed */
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}